import React, { useRef, useState } from 'react';
import { Card, Link, Divider, TextArea } from '@naos/ioe-ui-library';
import MarkdownPreview from '@uiw/react-markdown-preview';

const BlogPostComment = ({
  id,
  author,
  changeable,
  content,
  children,
  edited,
  onEditAction,
  onDeleteAction,
  onAnswerAction,
  markdown,
}) => {
  const oldcontent = useRef(content);
  const [scontent, setSContent] = useState(content);
  const [acontent, setAContent] = useState(''); 
  const [hidden, setHidden] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [answering, setAnswering] = useState(false);
  const [sedited, setSEdited] = useState(edited || false);
  const [error, setError] = useState('');

  const childcount = children ? React.Children.count(children) : 0;

  return (
    <div>
    <Card
      id={id}
      className={`rounded-md border-t border-t-grayBgCard shadow w-full px-2 pb-3 mb-2`}
    >
      <div className="relative flex flex-col">
        <div>
          <span className="text-[0.8rem] text-grayBgTags antialiased mr-5">
            {author} {sedited && <span>&sdot; (editiert)</span>}
          </span>
          {!editing &&
            (changeable ? (
              <span>
                <Link
                  text="Editieren"
                  onClick={() => {
                    setEditing(true);
                  }}
                  className="text-[0.8rem] inline"
                />
                <span className="text-secondary"> &sdot; </span>
                <Link
                  text={!deleting ? 'Löschen' : 'Löschen Bestätigen'}
                  onClick={() => {
                    if (!deleting) {
                      setDeleting(true);
                      if (hidden) setHidden(false);
                    } else {
                      onDeleteAction(id);
                    }
                  }}
                  className="text-[0.8rem] inline text-rose-600"
                  nocolor
                />
              </span>
            ) : !answering ? (
              <Link
                text="Antworten"
                onClick={() => setAnswering(true)}
                className="text-[0.8rem] inline"
              />
            ) : (
              ''
            ))}
        </div>
        <div data-color-mode="light">
          {!editing ? (
            markdown ? (
              <>
                
                <MarkdownPreview
                style={{
                  backgroundColor: 'inherit',
                  color: 'rgba(80, 80, 80, 0.9)',
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                }}
                source={scontent}
              />
                
              </>
            ) : (
              <span className="text-sm text-gray antialiased">
                {scontent}
              </span>
            )
          ) : (
            <div className="relative block">
              <TextArea
                rows={3}
                length="full"
                onChange={(e) => setSContent(e.target.value)}
                placeholder="Kommentar editieren"
                sharp
                value={scontent}
                className="transition ease-in-out duration-150 border-b border-solid border-grayBgTags focus:border-secondary shadow-none text-sm text-gray antialiased resize-y"
              />
            </div>
          )}
          {answering && (
            <div className="pt-2">
              <Divider length={100} />
              <div className="mt-2">
                <TextArea
                  length="full"
                  onChange={(e) => setAContent(e.target.value)}
                  sharp
                  rows={3}
                  className="transition ease-in-out duration-150 border-b border-solid border-grayBgTags focus:border-secondary shadow-none text-sm text-gray antialiased resize-y"
                  placeholder="Ihre Antwort"
                  value={acontent}
                />
              </div>
            </div>
          )}
        </div>
        <div
          className={`ml-0.5 flex items-center ${
            childcount > 0 && !answering ? 'justify-between' : 'justify-end'
          }`}
        >
          {childcount > 0 && !answering && (
            <Link
              text={
                !hidden
                  ? 'Antworten Verbergen'
                  : `${childcount} Antwort${
                      childcount > 1 ? 'en' : ''
                    } anzeigen`
              }
              onClick={() => setHidden(!hidden)}
              className="text-[0.8rem] inline"
            />
          )}
          {(editing || answering) && (
            <div>
              <Link
                text={answering ? 'Senden' : 'Speichern'}
                onClick={() => {
                  if (editing) {
                    if (!scontent)
                      return setError('Bitte füllen Sie das Feld aus!');
                    setEditing(false);
                    onEditAction(id, scontent);
                    oldcontent.current = scontent;
                    if (!sedited) setSEdited(true);
                  } else if (answering) {
                    if (!acontent)
                      return setError('Bitte füllen Sie das Feld aus!');
                    setAnswering(false);
                    onAnswerAction(id, acontent);
                    setAContent('');
                  }
                  setError('');
                }}
                className="text-[0.8rem] inline"
              />
              <span className="text-secondary"> &sdot; </span>
              <Link
                text="Abbrechen"
                onClick={() => {
                  if (editing) {
                    setSContent(oldcontent.current);
                    setEditing(false);
                  } else if (answering) {
                    setAnswering(false);
                  }
                  setError('');
                }}
                className="text-[0.8rem] inline"
              />
            </div>
          )}
        </div>
        {error && <div className="text-rose-600 text-sm">{error}</div>}
      </div>
    </Card>
    {!hidden && children && (
      <div className="pl-3 ml-3 mb-3 pt-3 border-l border-grayBgTags">
        {children}
      </div>
    )}
  </div>
)};

export default BlogPostComment;
