import TagManager from 'react-gtm-module';
import { BASE_URL, AUTH_URL } from "../config";

const getUserLikedPosts = async (likerId, token, query) => {
  try {
    const res = await fetch(
      BASE_URL +
        "/api/posts/liked/" +
        likerId +
        "?" +
        new URLSearchParams(query),
      {
        credentials: 'include',
      }
    );
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const getPosts = async (token, query) => {
  try {
    const res = await fetch(
      BASE_URL + "/api/posts?" + new URLSearchParams(query),
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
          // additional headers if needed
        },
      }
    );
    if (res.status === 401) {
      window.location.href = AUTH_URL;
    }
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const getPost = async (postId, token) => {
  try {
    const res = await fetch(BASE_URL + "/api/posts/" + postId, {
      headers: {
        "x-access-token": token,
      },
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const createPost = async (post, user) => {
  try {
    const res = await fetch(BASE_URL + "/api/posts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(post),
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'POST_CREATED',
        category: 'community',
        label: 'post created',
        value: 1
      }
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const updatePost = async (postId, user, data) => {
  try {
    const res = await fetch(BASE_URL + "/api/posts/" + postId, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(data),
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'POST_UPDATED',
        category: 'community',
        label: 'post updated',
        value: 1
      }
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const deletePost = async (postId, user) => {
  try {
    const res = await fetch(BASE_URL + "/api/posts/" + postId, {
      method: "DELETE",
      headers: {
        "x-access-token": user.token,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'POST_DELETED',
        category: 'community',
        label: 'post deleted',
        value: 1
      }
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const getComments = async (params) => {
  try {
    const { id } = params;
    const res = await fetch(BASE_URL + "/api/comments/post/" + id);
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const getUserComments = async (params) => {
  try {
    const { id, query } = params;
    const res = await fetch(
      BASE_URL + "/api/comments/user/" + id + "?" + new URLSearchParams(query)
    );
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const createComment = async (comment, params, user) => {
  try {
    const { id } = params;
    const res = await fetch(BASE_URL + "/api/comments/" + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(comment),
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'COMMENT_CREATED',
        category: 'community',
        label: 'comment created',
        value: 1
      }
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const updateComment = async (commentId, user, data) => {
  try {
    const res = await fetch(BASE_URL + "/api/comments/" + commentId, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(data),
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'COMMENT_UPDATED',
        category: 'community',
        label: 'comment updated',
        value: 1
      }
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const deleteComment = async (commentId, user) => {
  try {
    const res = await fetch(BASE_URL + "/api/comments/" + commentId, {
      method: "DELETE",
      headers: {
        "x-access-token": user.token,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'COMMENT_DELETED',
        category: 'community',
        label: 'comment deleted',
        value: 1
      }
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const likePost = async (postId, user) => {
  try {
    const res = await fetch(BASE_URL + "/api/posts/like/" + postId, {
      method: "POST",
      headers: {
        "x-access-token": user.token,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'POST_LIKED',
        category: 'community',
        label: 'post liked',
        value: 1
      }
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const unlikePost = async (postId, user) => {
  try {
    const res = await fetch(BASE_URL + "/api/posts/like/" + postId, {
      method: "DELETE",
      headers: {
        "x-access-token": user.token,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'POST_UNLIKED',
        category: 'community',
        label: 'post unliked',
        value: 1
      }
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

export {
  getPost,
  createPost,
  updatePost,
  deletePost,
  getPosts,
  getUserComments,
  getUserLikedPosts,
  getComments,
  createComment,
  deleteComment,
  updateComment,
  likePost,
  unlikePost,
};
