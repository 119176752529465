import getTopLevelDomain from "./components/util/getTopLevelDomain";

let BASE_URL = "https://app.mydomain.org/"; //"http://localhost:3001/";
if (process.env.REACT_APP_HOST) {
  BASE_URL = `https://${process.env.REACT_APP_HOST}`;
}

if (process.env.REACT_APP_URL) {
  BASE_URL = process.env.REACT_APP_URL;
}

let BE_URL = "http://api.mydomain.org/"; //"http://localhost:3000/";
if (process.env.REACT_APP_BE_HOST) {
  BE_URL = `https://${process.env.REACT_APP_BE_HOST}/`;
}

if (process.env.REACT_APP_BE_URL) {
  BE_URL = process.env.REACT_APP_BE_URL;
}

let AUTH_HOST = "http://auth.mydomain.org/"; // "http://localhost:3000";
if (process.env.REACT_APP_AUTH_HOST) {
  AUTH_HOST = `https://${process.env.REACT_APP_AUTH_HOST}/`;
}

const AUTH_URL = `${AUTH_HOST}auth-gateway?redirectUrl=${BASE_URL}`;
const LOGOUT_URL = `${AUTH_HOST}logout?redirectUrl=${BASE_URL}`;
const MAIN_PAGE_URL = getTopLevelDomain(BASE_URL);
const GTM_ID = "GTM-NZZ2ZBW";

export { BASE_URL, BE_URL, AUTH_URL, LOGOUT_URL, MAIN_PAGE_URL, GTM_ID };
