import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import React, { useEffect, useState } from "react";
import GoBack from "../GoBack";
import Loading from "../Loading";
import PostCard from "../PostCard";
import { useParams } from "react-router-dom";
import { getPost } from "../../api/posts";
import Comments from "../Comments";
import ErrorAlert from "../ErrorAlert";

const PostView = () => {
  const params = useParams();

  const [post, setPost] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const sessionContext = useSessionContext();
  const user = sessionContext;

  const fetchPost = async () => {
    setLoading(true);
    const data = await getPost(params.id, user && user.token);
    if (data?.error) {
      setError(data?.error);
    } else {
      setPost(data);
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPost();
  }, [params.id]);

  return (
    <section className="mx-auto max-w-7xl px-6 pt-28 lg:px-8">
      <GoBack />
      <div className="mt-5">
        {loading ? (
          <Loading />
        ) : post ? (
          <div className="flex flex-col justify-center space-y-2 w-full">
            <PostCard post={post} key={post._id} />
            <Comments />
          </div>
        ) : (
          error && <ErrorAlert error={error} />
        )}
      </div>
    </section>
  );
};

export default PostView;
