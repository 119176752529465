import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { deleteComment, updateComment, createComment } from "../api/posts";
import obfuscateEmail from "./util/obfuscateEmail";

import BlogPostComment from "./BlogPostComment";

const Comment = (props) => {
  const { addComment, removeComment, editComment } = props;
  const commentData = props.comment;
  const [comment, setComment] = useState(commentData);
  
  const sessionContext = useSessionContext();
  const isLoggedIn = !!sessionContext?.userId
  const user = sessionContext;

  const [error, setError] = useState("");

  const isAuthor = user && user.userId === comment.commenter._id;

  const navigate = useNavigate();
  const { id } = useParams();

  const handleAnswer = async (parentId, content) => {
    if (isLoggedIn) {
      const data = await createComment(
        { content, parentId },
        { id },
        isLoggedIn
      );

      if (data?.error) {
        setError(data?.error);
      } else {
        addComment(data);
      }
    } else {
      navigate("/login");
    }
  };

  const handleEdit = async (id, newval) => {
    const content = newval;
    await updateComment(comment._id, user, { content });
    const newCommentData = { ...comment, content, edited: true };
    setComment(newCommentData);
    editComment(newCommentData);
  };

  const handleDelete = async () => {
    await deleteComment(comment._id, user);
    removeComment(comment);
  };

  console.log('rendering comment', comment);
  return (
    <BlogPostComment
      id={comment._id}
      key={comment._id}
      content={comment.content}
      author={`${obfuscateEmail(comment.commenter.username)} ${isAuthor ? "(Sie)" : ""}`}
      changeable={isAuthor}
      edited={comment.edited}
      onDeleteAction={handleDelete}
      onEditAction={handleEdit}
      onAnswerAction={handleAnswer}
      markdown
    >
      {(error || comment.children.length > 0) && (
        <>
          {error && <span className="text-sm text-rose-600">{error}</span>}
          {comment.children.length > 0 &&
            comment.children.map((c) => (
              <Comment
                key={c._id}
                comment={c}
                addComment={addComment}
                removeComment={removeComment}
                editComment={editComment}
              />
            ))}
          
        </>
      )}
    </BlogPostComment>
  );
};

export default Comment;
