import React, { useState, Fragment, useEffect } from "react";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';


import { Menu, Transition, Dialog } from "@headlessui/react";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { AUTH_URL, LOGOUT_URL, MAIN_PAGE_URL } from "../config";
import { checkPermission, getUrl } from "../prismic";

const NavbarHome = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [navitem] = useAllPrismicDocumentsByType("nav_item", {
    orderings: [{ field: "my.nav_item.nav_item_position", direction: "asc" }],
  });

  const [filteredNavItems, setFilteredNavItems] = useState([]);


  const logout = (e) => {
    e.preventDefault();
    window.location.href = LOGOUT_URL;
  }

  const sessionContext = useSessionContext();
  const loggedIn = !!sessionContext?.userId;
  const role = loggedIn ? 'regular_user' : 'anonymous_user';

  useEffect(() => {
   const filtered = [];
    navitem?.forEach((item) => {
      if (item.data.body.length > 0 && item.data.body[0].items) {
        const i = item.data.body[0];
        const items = i.items.filter((it) => {
          if (it.permission_sub) {
            const permission = checkPermission(role, it.permission_sub.id);
            return permission;
          }
          return true;
        });
        if (items.length > 0) {
          filtered.push({ ...item, data: { ...item.data, body: [{ ...i, items }] } });
        }
      } else if (item.data.permission) {
        const permission = checkPermission(role, item.data.permission.id);
        if (permission) {
          filtered.push(item);
        }
        
      } else {
        filtered.push(item);
      }
    });
    setFilteredNavItems(filtered);
    console.log('GOT FILTERED ITEMS', filtered);
  }, [navitem, role]);



  console.log('SESSIONCONTEXT', sessionContext);

  console.log('LOADING NAVBAR HOME', navitem);

  return (
    navitem && (
            <header className="fixed left-0 top-0 w-full z-10 ease-in duration-300 bg-white">
              <nav className="flex items-center justify-between p-6 lg:px-8 mx-auto max-w-7xl " aria-label="Global">
                <div className="flex lg:flex-1 items-center">
                  <a href={MAIN_PAGE_URL}>
                      <img src="/IoE_logo_Standard.png" alt="IOE EXPERT LOGO" width={70} height={48} />
                  </a>
                </div>
                <div className="flex lg:hidden">
                  <button
                    type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black"
                    onClick={() => setMobileMenuOpen(true)}
                  >
                    <span className="sr-only">Open main menu</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                  </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                  {filteredNavItems.map((itemElement) =>
                    itemElement.data.body.length > 0 && itemElement.data.body[0].items ? (
                      <Menu as="a" className="relative inline-block text-left">
                        <Menu.Button
                          className="flex items-center font-bold uppercase leading-6 text-xs py-4 px-2 text-black"
                        >
                          {itemElement.data.nav_item_name}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="ml-1 h-4 w-4"
                            aria-hidden="true"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                          </svg>
                        </Menu.Button>
      
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-10 w-56 origin-top-right bg-white focus:outline-none">
                            <div className="p-3 border-b-2 border-b-secondary">
                              <Menu.Item>
                                <>
                                  {itemElement.data.body.map((item) =>
                                    item.items.map((i) => (
                                      <li key={i.dropdown_item_name} className="list-none pb-4">
                                        <a href={getUrl(i.dropdown_item_route)} className="font-bold uppercase leading-6 text-xs">
                                          {i.dropdown_item_name}
                                        </a>
                                      </li>
                                    ))
                                  )}
                                </>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      <a
                        href={getUrl(itemElement.data.nav_item_route)}
                        className="text-xs font-semibold leading-6 uppercase py-4 px-2 text-black"
                      >
                        {itemElement.data.nav_item_name}
                      </a>
                    )
                  )}
                  {!loggedIn &&
                   <a href={AUTH_URL} className="text-xs font-semibold leading-6 uppercase py-4 px-2 text-black cursor-pointer">
                    anmelden
                    </a>}
                  {loggedIn &&
                  <a onClick={logout} className="text-xs font-semibold leading-6 uppercase py-4 px-2 text-black cursor-pointer">
                      abmelden
                  </a>}
                </div>
              </nav>
              <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray">
                  <div className="flex items-center justify-between">
                    <a href="/">
                      <img src="/IoE_logo_White.png" alt="IOE EXPERT LOGO" width={70} height={48} />
                    </a>
                    <button type="button" className="-m-2.5 rounded-md p-2.5 text-white" onClick={() => setMobileMenuOpen(false)}>
                      <span className="sr-only">Close menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <div className="flex justify-center items-center w-full h-screen bg-gray text-center ease-in duration-300">
                    <div className="-my-6 divide-y divide-white">
                      <div className="space-y-2 py-6">
                        {filteredNavItems.map((itemElement) =>
                          itemElement.data.body.length > 0 && itemElement.data.body[0].items ? (
                            <Menu as="a" className="relative inline-block text-left">
                              <Menu.Button className="flex items-center font-bold uppercase leading-6 text-xs py-4 px-2 text-white">
                                {itemElement.data.nav_item_name}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="ml-1 h-4 w-4"
                                  aria-hidden="true"
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                </svg>
                              </Menu.Button>
      
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute z-10 w-56 origin-top-right bg-white focus:outline-none">
                                  <div className="p-3 border-b-2 border-b-secondary">
                                    <Menu.Item>
                                      <>
                                        {itemElement.data.body.map((item) =>
                                          item.items.map((i) => (
                                            <li key={i.dropdown_item_name} className="list-none pb-4">
                                              <a
                                                href={getUrl(i.dropdown_item_route)}
                                                className="-mx-3 block rounded-lg py-2 px-3 text-xs font-semibold leading-7 uppercase"
                                              >
                                                {i.dropdown_item_name}
                                              </a>
                                            </li>
                                          ))
                                        )}
                                      </>
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          ) : (
                            <a
                              href={getUrl(itemElement.data.nav_item_route)}
                              className="-mx-3 block rounded-lg py-2 px-3 text-xs font-semibold leading-7 uppercase text-white"
                            >
                              {itemElement.data.nav_item_name}
                            </a>
                          )
                        )}
                      </div>
                      {!loggedIn && <div className="py-6">
                        <a
                          href={AUTH_URL}
                          className="-mx-3 block rounded-lg py-2.5 px-3 text-xs font-semibold leading-7 uppercase text-white"
                        >
                          anmelden
                        </a>
                      </div>}
                      {loggedIn && <div className="py-6">
                        <a
                          href="#"
                          onClick={logout}
                          className="-mx-3 block rounded-lg py-2.5 px-3 text-xs font-semibold leading-7 uppercase text-white"
                        >
                          abmelden
                        </a>
                      </div>}
                    </div>
                  </div>
                </Dialog.Panel>
              </Dialog>
            </header>
    )
  );
};
export default NavbarHome;
