import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getPosts, getUserLikedPosts } from "../api/posts";

import PostCard from "./PostCard";
import { IconButton, SearchResultHeader } from "@naos/ioe-ui-library";
import Loading from "./Loading";

// TODO: select fix
const PostBrowser = (props) => {
  const navigate = useNavigate();

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [end, setEnd] = useState(false);
  const [sortBy, setSortBy] = useState("-createdAt");
  const [count, setCount] = useState(0);

  const sessionContext = useSessionContext();
  const user = sessionContext;

  const [search] = useSearchParams();
  const [effect, setEffect] = useState(false);

  const searchExists =
    search && search.get("search") && search.get("search").length > 0;

  const handleSearch = (request) => {
    navigate("/?" + new URLSearchParams({ search: request }));
  };

  const fetchPosts = async () => {
    setLoading(true);
    const newPage = page + 1;
    setPage(newPage);

    let query = {
      page: newPage,
      sortBy,
    };

    let data;

    if (props.contentType === "posts") {
      if (props.profileUser) query.author = props.profileUser.username;
      if (searchExists) query.search = search.get("search");

      data = await getPosts(user && user.token, query);
    } else if (props.contentType === "liked") {
      data = await getUserLikedPosts(
        props.profileUser._id,
        user && user.token,
        query
      );
    }

    if (data?.data?.length < 10) {
      setEnd(true);
    }

    setLoading(false);
    const d = data?.data?.length > 0 ? data.data : [];
    if (!data?.error) {
      setPosts([...posts, ...d]);
      setCount(data.count);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [sortBy, effect]);

  useEffect(() => {
    setPosts([]);
    setPage(0);
    setEnd(false);
    setEffect(!effect);
  }, [search]);

  const handleSortBy = (selected) => {
    /* const newSortName = selected;
    let newSortBy;

    Object.keys(sorts).forEach((sortName) => {
      if (sorts[sortName] === newSortName) newSortBy = sortName;
    });

    setPosts([]);
    setPage(0);
    setEnd(false);
    setSortBy(newSortBy); */
  };

  const removePost = (removedPost) => {
    setPosts(posts.filter((post) => post._id !== removedPost._id));
  };

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /* const contentTypeSorts = {
    posts: {
      "-createdAt": "Latest",
      "-likeCount": "Likes",
      "-commentCount": "Comments",
      createdAt: "Earliest",
    },
    liked: {
      "-createdAt": "Latest",
      createdAt: "Earliest",
    },
  };

  const sorts = contentTypeSorts[props.contentType]; */

  return (
    <div className="flex flex-col justify-center ">
      <div className="mb-12">
        <SearchResultHeader
          onSearchAction={handleSearch}
          btnOnClick={() => navigate("/posts/create")}
          
        />
      </div>
      <div className="space-y-2">
        {posts.map((post, i) => (
          <PostCard
            preview="primary"
            key={post._id}
            post={post}
            removePost={removePost}
          />
        ))}
        {loading && <Loading label="Lade ..." />}
      </div>
      <div className="mt-12 flex flex-col items-center space-y-3">
        {!loading && (
          <>
            {end ? (
              <span className="text-lg text-secondary">
                {posts.length > 0
                  ? "Alle Beiträge wurden gelesen"
                  : "Keine Beiträge verfügbar"}
              </span>
            ) : (
              <IconButton
                onClick={fetchPosts}
                color="secondary"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"
                    />
                  </svg>
                }
                text="Mehr Laden"
                type="button"
              />
            )}
            {posts.length > 0 && (
              <IconButton
                onClick={handleBackToTop}
                color="primary"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                }
                text="Nach Oben"
                type="button"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PostBrowser;
