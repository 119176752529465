import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { createComment } from "../api/posts";
import { Card, SmallBlogForm, Link } from "@naos/ioe-ui-library";

const CommentEditor = ({ comment, addComment, setReplying }) => {
  const [error, setError] = useState("");
  const sessionContext = useSessionContext();
  const isLoggedIn = !!sessionContext?.userId

  const params = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (content) => {
    const body = {
      content,
      parentId: comment && comment._id,
    };

    const data = await createComment(body, params, sessionContext);

    if (data?.error) {
      setError(data?.error);
    } else {
      setReplying && setReplying(false);
      addComment(data);
    }
  };

  return isLoggedIn ? (
    <div>
      <SmallBlogForm
        markdown
        onSubmit={handleSubmit}
        title="Kommentare"
        placeholder="Was denken Sie über diesen Beitrag?"
      />
      <span className="ml-1 mt-1 text-rose-600 text-md block">{error}</span>
    </div>
  ) : (
    <Card
      className={`rounded-md border-t border-t-grayBgCard shadow w-full px-2 pb-3`}
    >
      <span className="text-lg text-black block">Kommentare</span>
      <div className="text-lg">
      <Link
        className="inline !text-base"
        text="Melden Sie sich an"
        onClick={() => navigate("/login")}
      />{" "}
      <span className="inline text-base text-gray">
        um einen Kommentar hinterlassen zu können
      </span>
      </div>
    </Card>
  );
};

export default CommentEditor;
