import { IconButton } from "@naos/ioe-ui-library";
import React from "react";
import { useNavigate } from "react-router-dom";

const GoBack = () => {
  const navigate = useNavigate(); 
  return (
    <IconButton
        className="shadow-none"
        text="Zurück"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
        }
        onClick={() => {
          navigate("/");
        }}
        color="transparent"
      />
  );
};

export default GoBack;
