import React from "react";
import {Spinner} from "@naos/ioe-ui-library"

const Loading = ({ label }) => {
  return (
    <Spinner label={label} />
  );
};

export default Loading;
