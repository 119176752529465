import React, { useEffect, useState } from "react";
import Comment from "./Comment";
import Loading from "./Loading";
import { getComments } from "../api/posts";
import { useParams } from "react-router-dom";
import CommentEditor from "./CommentEditor";

const Comments = () => {
  const [comments, setComments] = useState(null);
  const [rerender, setRerender] = useState(false);
  const [error, setError] = useState("");
  const params = useParams();

  const fetchComments = async () => {
    const data = await getComments(params);
    if (data?.error) {
      setError("Failed to fetch comments");
    } else {
      setComments(data);
    }
  };

  useEffect(() => {
    setComments(comments);
  }, [rerender]);

  useEffect(() => {
    fetchComments();
  }, []);

  const findComment = (id) => {
    let commentToFind;

    const recurse = (comment, id) => {
      if (comment._id === id) {
        commentToFind = comment;
      } else {
        for (let i = 0; i < comment.children.length; i++) {
          const commentToSearch = comment.children[i];
          recurse(commentToSearch, id);
        }
      }
    };

    for (let i = 0; i < comments.length; i++) {
      const comment = comments[i];
      recurse(comment, id);
    }

    return commentToFind;
  };

  const removeComment = (removedComment) => {
    if (removedComment.parent) {
      const parentComment = findComment(removedComment.parent);
      parentComment.children = parentComment.children.filter(
        (comment) => comment._id !== removedComment._id
      );
      setRerender(!rerender);
      console.log("rerender");
    } else {
      setComments(
        comments.filter((comment) => comment._id !== removedComment._id)
      );
    }
    setError("");
  };

  const editComment = (editedComment) => {
    if (editedComment.parent) {
      let parentComment = findComment(editedComment.parent);
      for (let i = 0; i < parentComment.children.length; i++) {
        if (parentComment.children[i]._id === editedComment._id) {
          parentComment.children[i] = editedComment;
        }
      }
    } else {
      for (let i = 0; i < comments.length; i++) {
        if (comments[i]._id === editedComment._id) {
          comments[i] = editedComment;
        }
      }
      setRerender(!rerender);
    }
    setError("");
  };

  const addComment = (comment) => {
    if (comment.parent) {
      console.log(comment.parent);
      const parentComment = findComment(comment.parent);
      parentComment.children = [comment, ...parentComment.children];

      setRerender(!rerender);
    } else {
      setComments([comment, ...comments]);
    }
    setError("");
  };

  return (
    <>
      {comments ? (
        <div className="w-full">
          <CommentEditor
            addComment={addComment}
            label="Was halten Sie von diesem Beitrag?"
          />

          {comments.length > 0 ? (
            <div className="my-4">
              {comments.map((comment, i) => (
                <Comment
                  addComment={addComment}
                  removeComment={removeComment}
                  editComment={editComment}
                  comment={comment}
                  key={comment._id}
                  depth={0}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center mt-5">
              <span className="block text-2xl text-gray text-center">
                Noch keine Kommentare vorhanden...
              </span>
              <span className="block mt-1 text-md text-gray">
                Seien Sie der erste der einen Kommentar abgibt
              </span>
            </div>
          )}
        </div>
      ) : (
        <Loading label="Kommentare laden" />
      )}
      {error && <span className="text-sm text-rose-600">{error}</span>}
    </>
  );
};

export default Comments;
