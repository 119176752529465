import React, { useState } from "react";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { useNavigate } from "react-router-dom";
import { BlogForm } from "@naos/ioe-ui-library";

import { createPost } from "../../api/posts";
import GoBack from "../GoBack";

const CreatePostView = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const sessionContext = useSessionContext();
  const user = sessionContext;
  const isLoggedIn = !!sessionContext?.userId

  const handleSubmit = async (credentials) => {
    setLoading(true);
    const data = await createPost(credentials, isLoggedIn);
    setLoading(false);
    if (data && data?.error) {
      setServerError(data?.error);
    } else {
      navigate("/posts/" + data._id);
    }
  };

  return (
    <section className="mx-auto max-w-7xl px-6 pt-28 lg:px-8">
      <GoBack />
      <div className="mt-5">
        <BlogForm
          hasTitle
          markdown
          name="Neuen Communityeintrag schreiben"
          subtext="Mit der Veröffentlichung dieses Beitrages machen Sie ihn für jeden Benutzer sichtbar."
          onSubmit={handleSubmit}
        />
        <div className="flex flex-row justify-between mx-0.5 mt-2">
          <span className="text-md text-rose-600">
            {serverError}
          </span>
          <span className="text-md text-secondary">{loading ? 'Wird veröffentlicht...' : ''}</span>
        </div>
      </div>
    </section>
  );
};

export default CreatePostView;
