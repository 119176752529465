import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { BlogPost } from "@naos/ioe-ui-library";
import { deletePost, likePost, unlikePost, updatePost } from "../api/posts";
import obfuscateEmail from "./util/obfuscateEmail";

const PostCard = (props) => {
  const { preview, removePost } = props;
  let postData = props.post;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const sessionContext = useSessionContext();
  const user = sessionContext;
  const isLoggedIn = !!sessionContext?.userId
  const isAuthor = user && user.username === postData.poster.username;

  const [post, setPost] = useState(postData);
  const [likeCount, setLikeCount] = useState(post.likeCount);
  const liked = useRef(post.liked);

  const handleDeletePost = async () => {
    setLoading(true);
    await deletePost(post._id, isLoggedIn);
    setLoading(false);
    if (preview) {
      removePost(post);
    } else {
      navigate("/");
    }
  };

  const handleEditPost = async (id, newval) => {
    const content = newval;
    await updatePost(post._id, isLoggedIn(), { content });
    setPost({ ...post, content, edited: true });
  };

  const handleLike = () => {
    if (!isLoggedIn) return navigate("/login");
    if (!liked.current) {
      setLikeCount(likeCount + 1);
      likePost(post._id, user);
    } else {
      setLikeCount(likeCount - 1);
      unlikePost(post._id, user);
    }
    liked.current = !liked.current;
  };

  return (
    <BlogPost
      author={`${obfuscateEmail(post.poster.username)} ${isAuthor ? "(Sie)" : ""}`}
      changeable={isAuthor}
      id={post._id}
      commentCount={post.commentCount}
      content={post.content}
      likeCount={likeCount}
      title={post.title}
      onDeleteAction={handleDeletePost}
      onLikeAction={handleLike}
      onEditAction={handleEditPost}
      onViewDetailAction={() => navigate("/posts/" + post._id)}
      edited={post.edited}
      preview={props.preview === "primary" ? "explore" : "detail"}
      styleTheme={props.preview === "primary" ? "card" : "flat"}
      liked={liked.current}
    />
  );
};

export default PostCard;
