function getTopLevelDomain(url) {
  let urlObject = new URL(url);
  let hostname = urlObject.hostname;
  let protocol = urlObject.protocol;

  let splitArr = hostname.split('.');
  let arrLen = splitArr.length;

  //extracting the root domain here
  if (arrLen > 2) {
      hostname = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
      //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
      if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
          //this is using a ccTLD
          hostname = splitArr[arrLen - 3] + '.' + hostname;
      }
  }
  return protocol + "//" + hostname;
}

export default getTopLevelDomain;
