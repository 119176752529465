import "react-icons";
import "react-icons/bi";
import "react-icons/md";
import "react-icons/bs";
import "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import PostView from "./components/views/PostView";
import CreatePostView from "./components/views/CreatePostView";

import ExploreView from "./components/views/ExploreView";


import Footer from "./components/Footer";
import NavbarHome from "./components/NavbarHome";


import { AUTH_URL } from "./config";
import FullScreenLoader from "./components/FullscreenLoader";

function App() {
  const loginUrl = AUTH_URL + window.location?.pathname;
  const session = useSessionContext();

  console.log(loginUrl, session);

  if (session.loading === false && !session?.userId) {
    setTimeout(() => {
      window.location.assign(loginUrl);
    }, 300);
    return null;
  }

  if (session?.loading) {
    return <FullScreenLoader />;
  } 
  return (
    <div className="font-helvetica text-base">
      <NavbarHome />
      <Routes>
        <Route path="/" element={<ExploreView />} />
        <Route path="/posts/:id" element={<PostView />} />
        <Route
          path="/posts/create"
          element={
            <CreatePostView />
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
