import React, { useEffect } from "react";
import PostBrowser from "../PostBrowser";

const ExploreView = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []);
  return (
    <>
      <section className="mx-auto max-w-7xl px-6 lg:px-8 pt-28">
        <PostBrowser createPost contentType="posts" />
      </section>
    </>
  );
};

export default ExploreView;
