import React from "react";

const ErrorAlert = ({ error }) => {
  return (
    error && (
      <span className="block text-rose-600 text-md">{error}</span>
    )
  );
};

export default ErrorAlert;
