import * as prismic from "@prismicio/client";
import { MAIN_PAGE_URL } from "./config";
export const repositoryName = "https://ioe-web.prismic.io/api/v2";
export const permissions = {
  ZQQwvBIAACEAozb2: 'paid_user',
  ZQQqehIAACEAoyqg: 'regular_user',
  ZQQn7hIAACEAoyXJ: 'anonymous_user',
}

export const checkPermission = (role, permissionId) => {
  const permission = permissions[permissionId];
  if (!permission) return false;
  if (role === 'paid_user') return true;
  if (role === 'regular_user') return permission !== 'paid_user';
  if (role === 'anonymous_user') return permission === 'anonymous_user';
}

export const getUrl = (url) => {
  if (url && url.indexOf('http') === 0) {
    return url;
  }
  return `${MAIN_PAGE_URL}${url}`;
}

export const client = prismic.createClient(repositoryName);

