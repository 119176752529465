import React from "react";

const navigation = {
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/IofEntrepreneurship/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/company/ioe/',
      icon: (props) => (
        <svg
          id="Group_1282"
          data-name="Group 1282"
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          viewBox="0 0 76.624 65.326"
        >
          <path
            id="Path_2525"
            data-name="Path 2525"
            d="M1165,274.515a1.2,1.2,0,0,0,1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025,1.291,2.029h.724l-1.389-2.1Zm-.783-.472h-.785V272.45h.995c.514,0,1.1.084,1.1.757,0,.774-.593.836-1.314.836"
            transform="translate(-1092.136 -213.406)"
            fill="currentColor"
          />
          <path
            id="Path_2520"
            data-name="Path 2520"
            d="M958.98,112.559h-9.6V97.525c0-3.585-.064-8.2-4.993-8.2-5,0-5.765,3.906-5.765,7.939v15.294h-9.6V81.642h9.216v4.225h.129a10.1,10.1,0,0,1,9.093-4.994c9.73,0,11.524,6.4,11.524,14.726ZM918.19,77.416a5.571,5.571,0,1,1,5.57-5.572,5.571,5.571,0,0,1-5.57,5.572m4.8,35.143h-9.61V81.642h9.61Zm40.776-55.2h-55.21a4.728,4.728,0,0,0-4.781,4.67v55.439a4.731,4.731,0,0,0,4.781,4.675h55.21a4.741,4.741,0,0,0,4.8-4.675V62.025a4.738,4.738,0,0,0-4.8-4.67"
            transform="translate(-903.776 -57.355)"
            fill="currentColor"
          />
          <path
            id="Path_2526"
            data-name="Path 2526"
            d="M1156.525,264.22a4.418,4.418,0,1,0,.085,0h-.085m0,8.33a3.874,3.874,0,1,1,3.809-3.938c0,.022,0,.043,0,.065a3.791,3.791,0,0,1-3.708,3.871h-.1"
            transform="translate(-1084.362 -207.809)"
            fill="currentColor"
          />
        </svg>
      ),
    },
  ],
};

const Footer = () => {
  const getYear = new Date().getFullYear();
  return (
    <div className="pt-12 md:pt-20">
      <footer className="bg-black" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
          <div className=" flex flex-col space-y-8">
            <div>
              <img src="/IoE_logo_White.png" alt="IOE EXPERT LOGO" width={70} height={48}/>
            </div>
            <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-16">
              <div>
                {' '}
                <p className="text-sm leading-6 text-white">
                  <strong>IoE - Institute of Entrepreneurship Deutschland GmbH</strong>
                  <br />
                  <br />
                  Holzhausenstrasse 48, 60322 Frankfurt
                  <br />
                  T +49 (0) 151 640 57 066 <br />
                  E-Mail: office@ioe-deutschland.de <br />
                  UID-Nr.: DE286899638
                  <br />
                  Handelsregister: HRB 95457
                  <br />
                  Geschäftsführer: Dr. Johannes Jungblut
                  <br />
                  Registergericht: Amtsgereicht Frankfurt am Main
                </p>
              </div>
              <div>
                <p className="text-sm leading-6 text-white">
                  <strong>IoE – Institute of Entrepreneurship Unternehmertum GmbH</strong>
                  <br />
                  <br />
                  Mahlergasse 7, 3100 St. Pölten
                  <br />
                  Tel.: +43 1 5130 111 <br />
                  E-Mail: office@ioe-oesterreich.at <br />
                  UID-Nr.: ATU70324936
                  <br />
                  Firmenbuch-Nr.: FN344585v
                  <br />
                  Geschäftsführer: Dr. Johannes Jungblut
                </p>
              </div>
              <ul role="list" className="flex justify-center md:justify-start space-x-8 ">
                <li>
                  {' '}
                  <a href="https://ioe.expert/impresum" className="text-sm leading-6 text-secondary hover:text-secondary">
                    Impresum
                  </a>
                </li>
                <li>
                  {' '}
                  <a href="https://ioe.expert/datenschutz" className="text-sm leading-6 text-secondary hover:text-secondary">
                    Datenschutz
                  </a>
                </li>
                <li>
                  {' '}
                  <a href="" className="text-sm leading-6 text-secondary hover:text-secondary">
                    Cookie-Einstellung
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex space-x-6 py-8 md:py-4 justify-center md:justify-start">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-secondary hover:text-secondary">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-10 border-t border-secondary pt-8 sm:mt-20 lg:mt-24">
            <p className="text-xs leading-5 text-white">&copy; {getYear}, IOE.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
