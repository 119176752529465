import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Session from "supertokens-auth-react/recipe/session";
import App from "./App";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import "./styles/globals.css";
import { GTM_ID, BASE_URL, BE_URL } from "./config";
import { getDomainSuffix } from "./domain-helpers";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
const port = process.env.APP_PORT || 3001;
const websiteDomain = BASE_URL || `http://localhost:${port}`;
const apiDomain = BASE_URL || `http://localhost:${port}`;


console.log('SETTING PORT',port);
console.log('SETTING WEBSITE DOMAIN',websiteDomain);
console.log('SETTING API DOMAIN',apiDomain);

const tagManagerArgs = {
  gtmId: GTM_ID,
};

TagManager.initialize(tagManagerArgs);

SuperTokens.init({
  enableDebugLogs: true,
  appInfo: {
    appName: "app-ioe-auth",
    apiDomain: apiDomain,
    websiteDomain: websiteDomain,
    apiBasePath: "/api/auth",
    websiteBasePath: "/",
  },
  recipeList: [
    Session.init({
      enableDebugLogs: true,
      sessionTokenFrontendDomain: getDomainSuffix(websiteDomain),
      sessionTokenBackendDomain: getDomainSuffix(apiDomain),
    }),
  ],
});

root.render(
  <SuperTokensWrapper>
    <PrismicProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PrismicProvider>
  </SuperTokensWrapper>,
);
